import _styled from "@emotion/styled-base";
export var FooterWrapper = _styled("footer", {
  target: "e17ctmmk0"
})({
  name: "jjk1ua",
  styles: "display:flex;flex-direction:column;justify-content:center;width:100%;background-color:#ff6c00;padding:120px 0;"
});
export var Container = _styled("div", {
  target: "e17ctmmk1"
})({
  name: "wkpw2c",
  styles: "display:flex;flex-direction:column;justify-content:center;align-items:center;"
});
export var IconRow = _styled("div", {
  target: "e17ctmmk2"
})({
  name: "j18gcc",
  styles: "max-width:600px;display:flex;justify-content:center;align-items:center;margin-bottom:24px;"
});
export var IconLink = _styled("a", {
  target: "e17ctmmk3"
})({
  name: "18m8qrg",
  styles: "text-decoration:none;color:black;margin:0 16px;&:hover{text-decoration:none;opacity:0.75;}"
});
export var Copyright = _styled("span", {
  target: "e17ctmmk4"
})({
  name: "1kuy7z7",
  styles: "font-size:14px;"
});