import { jsx as ___EmotionJSX } from "@emotion/core";
import React from 'react';
import { Link } from 'gatsby';
import SeparatorImg from '../images/separator.png';
import SeparatorOrangeImg from '../images/separator-orange-top.png';
import SeparatorWhiteTopImg from '../images/separator-white-top.png';
import TechStackImg from '../images/techstack.png';
import SEO from '../components/seo';
import MainHero from '../components/MainHero';
import '../components/layout.css';
import Footer from '../components/Footer';
import { Section, OrangeSection, TechStackSection, Content, Separator, TopSeparator, SectionTitle } from '../styles';

var IndexPage = function IndexPage() {
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(SEO, {
    title: "Home"
  }), ___EmotionJSX(MainHero, null), ___EmotionJSX(OrangeSection, {
    separatorTop: true
  }, ___EmotionJSX(Content, null, ___EmotionJSX("p", null, "My Name is Osamah Aldoaiss, I am a Freelance UI Engineer and Technical Lead. There is a variety of tasks I can fulfill, including App Development, Frontend Architecture Consulting, Technical Lead & Coordination for your Project, Scrum Master, and so much", ' ', ___EmotionJSX(Link, {
    to: "/blog"
  }, "more"), "."), ___EmotionJSX("p", null, "I specialise in the following technologies: React, React Native, Typescript, CSS-in-JS (emotionJS), GatsbyJS, NodeJS, GraphQL and E-Commerce with Shopify."), ___EmotionJSX("p", null, "If you want to know more about me keep scrolling")), ___EmotionJSX(Separator, {
    imgSrc: SeparatorImg
  })), ___EmotionJSX(Section, {
    separatorTop: true
  }, ___EmotionJSX(Content, null, ___EmotionJSX(SectionTitle, null, "Development"), ___EmotionJSX("p", null, "I have been working as a Frontend Developer for 8 years now. It all started with a huge Refactoring project at a large Insurance company. After that I joined a startup and worked with Angular 1 and Angular 3, before switching companies again and working purely with a React Stack."), ___EmotionJSX("p", null, "At one point I was the only Frontend Developer left, which is when I refactored the whole Frontend of thehomelike.com by myself and set the basis for one of the fastest-growing products and startups in Germany. I was also leading the team of four Frontend Developers and helped develop their skills further."), ___EmotionJSX("p", null, "Currently I transitioned into Freelancing. Starting with just Development for an agency, I got offered the role of a Frontend Technical Lead and Coordinator in another project. I lead a team of 3 Frontend Developers as well as sighting all the requirements and preparing them for Developers."))), ___EmotionJSX(TechStackSection, {
    imgSrc: TechStackImg
  }, ___EmotionJSX(TopSeparator, {
    imgSrc: SeparatorWhiteTopImg
  }), ___EmotionJSX(Content, null, ___EmotionJSX(SectionTitle, null, "Tech Stack"), ___EmotionJSX("p", null, "I believe the Tech Stack I work with is the most cutting-edge, scalable and best-performant one, that can be implemented at the current. It all revolves around ReactJS, GraphQL and Typescript."), ___EmotionJSX("h3", null, "ReactJS"), ___EmotionJSX("p", null, "ReactJS makes it super easy to develop great UI, thanks to a great Component API and the newly added hooks and Context API. It has never been easy to build great UIs that solid and reusable. I complement my React codebase with emotionJS for styling, redux or zustand for data management, Formik for Form Handling and Apollo Client for the data layer."), ___EmotionJSX("h3", null, "GraphQL"), ___EmotionJSX("p", null, "GraphQL as the Data Layer for the Frontend, allows the Frontend to decide what data it needs and only fetching what is necessary for the UI. That way the performance of the application can be controlled much better. Depending if GraphQL is implemented into an existing codebase or creating a new one, the approach would be different. But in the long-run GraphQL offers any team a big improvement."), ___EmotionJSX("h3", null, "Typescript"), ___EmotionJSX("p", null, "Typescript is what ties ReactJS and GraphQL and the whole application together. Having the type safety of knowing what the backend delivers and also what API each component has. This reduces bugs and errors drastically, as well as increasing the DX of the codebase.")), ___EmotionJSX(Separator, {
    imgSrc: SeparatorOrangeImg
  })), ___EmotionJSX(OrangeSection, {
    separatorTop: true
  }, ___EmotionJSX(Content, null, ___EmotionJSX(SectionTitle, null, "GatsbyJS"), ___EmotionJSX("p", null, "For the longest time it was difficult to build performant websites and shops that can also be dynamic after the initial load. Frameworks like Jekyll or Wordpress make it difficult to add dynamic parts of a website. Thankfully Gatsby solves this issue in an elegant way."), ___EmotionJSX("p", null, "Gatsby generates during the build process static HTML pages, that can be served through any server and then hydrates the pages with React as soon as the page loads. This leads to fast load times, the possibility to only deliver necessary files for the initial page load through lazy loading and prefetching. This enables the Team to have near perfect scores with Lighthouse and blazing fast websites."), ___EmotionJSX("p", null, "I found that this is especially beneficial for the E-Commerce world, where speed is key and the faster the page loads, the better the Sales numbers. With GatsbyJS I achieved a page load time of 0.5s for my Shop, which no competitor can even come close to. And thanks to React hydration, it gives me the full power to design and develop with the full might of React and add great UX to the shop.")), ___EmotionJSX(Separator, {
    imgSrc: SeparatorImg
  })), ___EmotionJSX(Section, {
    separatorTop: true
  }, ___EmotionJSX(Content, null, ___EmotionJSX(SectionTitle, null, "Personal Projects and Open Source"), ___EmotionJSX("p", null, "There are several Projects I am passionate about, that I co-founded or that are created by me. There are on the one hand side-projects, which are profit-oriented and on the other Open Source Projects, that I want to support and keep maintaining."), ___EmotionJSX("p", null, "My side-projects are very close to my heart and I am working on the actively. The main Project that I created and that I am involved in is the RamadanMakers Community: A community for Muslim Makers, sponsored by Makerlog, that encourages more productivity."), ___EmotionJSX("p", null, "Open Source Projects are close to my hearts. After all, I work with Open Source Software for a living and I love to contribute back. So I decided to do something, that was motivated by my friend Manuel Bieh: I will add 1 \u20AC to my hourly rate, that supports my Open Source work and donations to projects I care about."), ___EmotionJSX("p", null, "Current Open Source Projects I am actively working on: Postonents, Gatsby, React, Formik."))), ___EmotionJSX(Footer, null));
};

export default IndexPage;