import _styled from "@emotion/styled-base";
import { jsx as ___EmotionJSX } from "@emotion/core";
import React from 'react';
import ProfileImg from '../../images/cropped-color.png';
import BG from '../../images/hero-pattern.png';
import SeparatorImg from '../../images/separator-orange-top.png';
import { Separator } from '../../styles';

var Section = _styled("section", {
  target: "ex5ypew0"
})("position:relative;width:100vw;height:100vh;background:url('", function (_ref) {
  var bgSrc = _ref.bgSrc;
  return bgSrc;
}, "') no-repeat center center;background-size:cover;");

var ParticlesContainer = _styled("div", {
  target: "ex5ypew1"
})({
  name: "1mmpr1b",
  styles: "width:100%;height:100%;position:absolute;z-index:1;top:0;left:0;"
});

var HeroContent = _styled("div", {
  target: "ex5ypew2"
})({
  name: "7u581m",
  styles: "position:relative;z-index:2;display:flex;align-items:flex-end;max-width:1216px;margin:0 auto;width:100%;height:100%;justify-content:flex-end;flex-direction:column;padding:0 16px 120px;@media (min-width:768px){justify-content:center;padding:0 40px;}"
});

var ImageWrapper = _styled("div", {
  target: "ex5ypew3"
})({
  name: "1d6h348",
  styles: "position:absolute;left:0;top:0;bottom:0;max-width:50vw;width:auto;overflow:hidden;display:flex;justify-content:center;align-items:center;margin-right:24px;z-index:-1;"
});

var MyProfile = _styled("img", {
  target: "ex5ypew4"
})({
  name: "1bt65qu",
  styles: "height:90vh;margin:0;object-fit:cover;object-position:center right;"
});

var PageTitle = _styled("h1", {
  target: "ex5ypew5"
})({
  name: "19cgur2",
  styles: "text-align:right;font-weight:900;font-size:50px;line-height:40px;margin:0;color:black;letter-spacing:2px;margin-bottom:8px;@media (min-width:768px){font-size:100px;line-height:90px;margin-bottom:12px;}@media (min-width:1024px){font-size:140px;line-height:112px;}"
});

var Subline = _styled("h2", {
  target: "ex5ypew6"
})({
  name: "8xh5e9",
  styles: "font-size:14px;font-weight:400;margin:0;@media (min-width:768px){font-size:16px;font-weight:300;}@media (min-width:768px){font-size:20px;}"
});

var MainHero = function MainHero() {
  return ___EmotionJSX(Section, {
    bgSrc: BG
  }, ___EmotionJSX(ParticlesContainer, null), ___EmotionJSX(HeroContent, null, ___EmotionJSX(ImageWrapper, null, ___EmotionJSX(MyProfile, {
    src: ProfileImg
  })), ___EmotionJSX(PageTitle, {
    color: "black"
  }, "OSAMAH", ___EmotionJSX("br", null), "ALDOAISS"), ___EmotionJSX(Subline, null, "UI Engineer \u25E6 Technical Lead \u25E6 Maker")), ___EmotionJSX(Separator, {
    imgSrc: SeparatorImg
  }));
};

export default MainHero;