import { jsx as ___EmotionJSX } from "@emotion/core";
import React from 'react';
import { IoLogoGithub, IoLogoTwitter, IoLogoInstagram, IoMdCheckmarkCircle } from 'react-icons/io';
import { FaTelegram } from 'react-icons/fa';
import { FooterWrapper, Container, IconRow, IconLink, Copyright } from './styles';

var Footer = function Footer() {
  return ___EmotionJSX(FooterWrapper, null, ___EmotionJSX(Container, null, ___EmotionJSX(IconRow, null, ___EmotionJSX(IconLink, {
    href: "https://www.github.com/Saifadin",
    target: "_blank",
    title: "Follow me on Github"
  }, ___EmotionJSX(IoLogoGithub, {
    size: "32",
    color: "black"
  })), ___EmotionJSX(IconLink, {
    href: "https://www.twitter.com/Saifadin",
    target: "_blank",
    title: "Follow me on Twitter"
  }, ___EmotionJSX(IoLogoTwitter, {
    size: "32",
    color: "black"
  })), ___EmotionJSX(IconLink, {
    href: "https://www.instagram.com/Saifadin",
    target: "_blank",
    title: "Follow me on Instagram"
  }, ___EmotionJSX(IoLogoInstagram, {
    size: "32",
    color: "black"
  })), ___EmotionJSX(IconLink, {
    href: "https://getmakerlog.com/@saifadin",
    target: "_blank",
    title: "Follow me on Makerlog"
  }, ___EmotionJSX(IoMdCheckmarkCircle, {
    size: "32",
    color: "black"
  })), ___EmotionJSX(IconLink, {
    href: "https://t.me/aldoaiss",
    target: "_blank",
    title: "Message me on Telegram"
  }, ___EmotionJSX(FaTelegram, {
    size: "32",
    color: "black"
  }))), ___EmotionJSX(Copyright, null, "\xA9 Osamah Aldoaiss / 2019 | Made with", ' ', ___EmotionJSX("span", {
    role: "img",
    "aria-label": "Heart"
  }, "\u2764\uFE0F"), ' ', "in Gatsby")));
};

export default Footer;